import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/my-profile",
        name: "my-profile",
        component: () => import("@/views/crafted/account/Profile.vue"),
      },
      // {
      //   path: "/pool",
      //   name: "pool",
      //   component: () => import("@/views/apps/pool/Index.vue"),
      // },
      {
        path: "/withdraws/usdt",
        name: "withdraw-usdt",
        component: () => import("@/views/apps/withdraw-usdt/Index.vue"),
      },
      {
        path: "/withdraws/mono",
        name: "withdraw-mono",
        component: () => import("@/views/apps/withdraw-mono/Index.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/apps/user/Index.vue"),
      },
      {
        path: "/users/:id",
        name: "user-detail",
        component: () => import("@/views/apps/user/UserDetail.vue"),
      },
      {
        path: "/public-sales",
        name: "public-sales",
        component: () => import("@/views/apps/public-sale/Index.vue"),
      },
      {
        path: "/public-sales/:slug/whitelist",
        name: "public-sale-whitelist",
        component: () => import("@/views/apps/public-sale/Whitelist.vue"),
      },
      {
        path: "/affiliate",
        name: "affiliate",
        component: () => import("@/views/apps/affiliate/Index.vue"),
      },
      {
        path: "/packages",
        name: "package",
        component: () => import("@/views/apps/package/Index.vue"),
      },
      {
        path: "/wallets/busd",
        name: "wallet-busd",
        component: () => import("@/views/apps/wallet/Busd.vue"),
      },
      {
        path: "/wallets/hnt",
        name: "wallet-hnt",
        component: () => import("@/views/apps/wallet/Hnt.vue"),
      },
      // {
      //   path: "/wallets/hnt-interest",
      //   name: "wallet-hnt-interest",
      //   component: () => import("@/views/apps/wallet/HntInterest.vue"),
      // },
      // {
      //   path: "/wallets/hnt-bonus",
      //   name: "wallet-hnt-bonus",
      //   component: () => import("@/views/apps/wallet/HntBonus.vue"),
      // },
      {
        path: "/admin-wallets/busd",
        name: "admin-wallet-busd",
        component: () => import("@/views/apps/wallet-admin/Busd.vue"),
      },
      {
        path: "/admin-wallets/hnt",
        name: "admin-wallet-hnt",
        component: () => import("@/views/apps/wallet-admin/Hnt.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
