import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import UserLocalStorageService from "@/core/services/UserLocalStorageService";
import { useRouter } from "vue-router";

export interface PackageInfo {
  errors: unknown;
}

const router = useRouter();

@Module
export default class AffiliateModule extends VuexModule implements PackageInfo {
  errors = {};
  buyPackageErrors = {};
  packageHistoryErrors = {};
  packages = [];
  userPurchasedPackages = [];
  packageHistories = [];

  /**
   * Get current packages object
   * @returns Packages
   */
  get getPackages() {
    return this.packages;
  }

  /**
   * Get current userPurchasedPackages object
   * @returns userPurchasedPackages
   */
  get getUserPurchasedPackages() {
    return this.userPurchasedPackages;
  }

  /**
   * Get current packageHistories object
   * @returns packageHistories
   */
  get getPackageHistories() {
    return this.packageHistories;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getPackageErrors() {
    return this.errors;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getPackageHistoryErrors() {
    return this.packageHistoryErrors;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getBuyPackageErrors() {
    return this.buyPackageErrors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_PACKAGE_HISTORY_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_ERROR_BUY_PACKAGE](error) {
    this.buyPackageErrors = { ...error };
  }

  @Mutation
  [Mutations.SET_PACKAGE](packages) {
    this.packages = packages;
  }

  @Mutation
  [Mutations.SET_USER_PURCHASED_PACKAGE](userPurchasedPackages) {
    this.userPurchasedPackages = userPurchasedPackages;
  }

  @Mutation
  [Mutations.SET_PACKAGE_HISTORY](packageHistories) {
    this.packageHistories = packageHistories;
  }

  @Action
  [Actions.GET_PACKAGE](params) {
    ApiService.setHeader();
    return ApiService.query("user/package", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PACKAGE, data.data.packages);
        this.context.commit(
          Mutations.SET_USER_PURCHASED_PACKAGE,
          data.data.user_packages
        );
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (response.status == 401) {
          UserLocalStorageService.destroyUser();
          router.push({ name: "sign-in" });
        } else {
          if (response.data && response.data.code) {
            this.context.commit(Mutations.SET_ERROR, {
              message: [response.data.message],
            });
          } else {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          }
        }
      });
  }

  @Action
  [Actions.GET_PACKAGE_HISTORY](params) {
    ApiService.setHeader();
    return ApiService.query("user/user-package-history", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PACKAGE_HISTORY, data.data.history);
        this.context.commit(Mutations.SET_PACKAGE_HISTORY_ERROR, {});
      })
      .catch(({ response }) => {
        if (response.status == 401) {
          UserLocalStorageService.destroyUser();
          router.push({ name: "sign-in" });
        } else {
          if (response.data && response.data.code) {
            this.context.commit(Mutations.SET_PACKAGE_HISTORY_ERROR, {
              message: [response.data.message],
            });
          } else {
            this.context.commit(
              Mutations.SET_PACKAGE_HISTORY_ERROR,
              response.data.errors
            );
          }
        }
      });
  }

  @Action
  [Actions.BUY_PACKAGE](params) {
    ApiService.setHeader();
    return ApiService.post("user/package", params)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR_BUY_PACKAGE, {});
      })
      .catch(({ response }) => {
        if (response.status == 401) {
          UserLocalStorageService.destroyUser();
          router.push({ name: "sign-in" });
        } else {
          if (response.data && response.data.code) {
            this.context.commit(Mutations.SET_ERROR_BUY_PACKAGE, {
              message: [response.data.message],
            });
          } else {
            this.context.commit(
              Mutations.SET_ERROR_BUY_PACKAGE,
              response.data.errors
            );
          }
        }
      });
  }
}
